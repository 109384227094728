import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ICourse, IStudent } from "./types/types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loginToken: string;
  studentId: number;
  availableCourses: ICourse[];
  currentCourse: ICourse | null;
  courseRegistrationModalOpen: boolean;
  viewStudentsForCourseModalOpen: boolean;
  studentList: IStudent[];
  signedUpCourses: ICourse[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PerformanceTrackerBasicUserAllCoursesScreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  registerForCourseApiCallId: string = "";
  getAvailableCoursesApiCallId: string = "";
  getAccountsForCourseApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loginToken: "",
      studentId: -1,
      availableCourses: [],
      currentCourse: null,
      courseRegistrationModalOpen: false,
      viewStudentsForCourseModalOpen: false,
      studentList: [],
      signedUpCourses: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
    // this.seeRequestedCourses();
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getAvailableCourses = async (token: string) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAvailableCoursesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAvailableCoursesApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  //GET accounts for course
  getAccountsForCourse = async (course_id: number) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAccountsForCourseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAccountsForCourseIdApiEndpoint.replace(
        ":course_id",
        course_id
      )
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSignedUpCoursesMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  registerForCourse = async (accountId: number, courseId: number) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.loginToken,
    };

    const body = {
      course_id: courseId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.registerForCourseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.requestCourseSignUpApiEndpoint.replace(
        ":account_id",
        accountId
      )
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.registerForCourseMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson?.errors) {
      this.parseApiErrorResponse(responseJson);
    }
    if (errorResponse) {
      this.parseApiCatchErrorResponse(errorResponse);
    }
    console.log("here");
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const loginToken: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", loginToken);
      const messageData = JSON.parse(
        message.getData(getName(MessageEnum.SessionResponseData))
      );
      const studentId: number = messageData?.meta?.id;
      this.setState({ studentId, loginToken }, () => {
        this.getAvailableCourses(loginToken);
      });
    }
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const studentData = message.getData(
        getName(MessageEnum.SessionResponseData)
      );
      const signedUpCourses = studentData?.signedUpCourses;
      if (signedUpCourses) {
        this.setState({ signedUpCourses: signedUpCourses ?? [] });
      }
    }

    const restApiDataCondition: boolean =
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id;
    if (
      restApiDataCondition &&
      this.getAvailableCoursesApiCallId != null &&
      this.getAvailableCoursesApiCallId === apiRequestCallId
    ) {
      if (responseJson?.data) {
        this.setState({
          availableCourses: responseJson.data,
        });
      }
    }
    //register course POST
    if (
      restApiDataCondition &&
      this.registerForCourseApiCallId != null &&
      this.registerForCourseApiCallId === apiRequestCallId
    ) {
      this.getAvailableCourses(this.state.loginToken); //refresh show awaiting approval
      // set state to course list - field - approved
    }
    if (
      restApiDataCondition &&
      this.getAccountsForCourseApiCallId != null &&
      this.getAccountsForCourseApiCallId === apiRequestCallId
    ) {
      this.setState({
        studentList: responseJson.data,
      });
    }
  }

  setViewStudentsForCourseModalOpen = (courseId: number) => {
    this.getAccountsForCourse(courseId);
    this.setState({ viewStudentsForCourseModalOpen: true });
  };

  hideStudentsForCourseModalOpen = () => {
    this.setState({ viewStudentsForCourseModalOpen: false });
  };

  setCourseRegistrationModalOpen = () => {
    this.setState({ courseRegistrationModalOpen: true });
  };
  // Customizable Area End
}
